export default {
  label: 'Forgot Password Settings',
  name: 'forgot',
  component: 'group',
  fields: [
    // ForgotForm settings
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'RESET PASSWORD',
    },
    {
      label: 'Subheading',
      name: 'subHeading',
      component: 'textarea',
      defaultValue:
        "Enter your email and we'll send you a link to reset your password.",
    },
    {
      label: 'Button Text',
      name: 'buttonText',
      component: 'text',
      defaultValue: 'Reset',
    },
    {
      label: 'Return Link Text',
      name: 'return',
      component: 'text',
      defaultValue: 'Back to Login',
    },

    // LoginRegisterLayout settings
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue:
        'https://images.unsplash.com/photo-1614098805147-61657513491f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=924&q=80',
      description: 'Image that will be seen on the right of the reset form.',
    },
  ],
};
